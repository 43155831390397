import React, {useEffect} from 'react'

import {BriefcaseIcon, DownloadIcon, HomeIcon, MailIcon, PhoneIcon, DeviceMobileIcon, GlobeAltIcon} from '@heroicons/react/outline'
import {useLazyQuery} from "@apollo/client";
import {GET_ONE_BUSINESS_CARD_BY_UUID} from "../../graphql/businessCardGraphQL";

export default function Public() {

    let uuid = ""
    if (typeof window !== 'undefined') {
        uuid = window.location.pathname.split("/").pop()
    }

    //loading
    const [loadBusinessCard, { queryLoading, queryError, data }] = useLazyQuery(GET_ONE_BUSINESS_CARD_BY_UUID )

    useEffect(() => {
        loadBusinessCard( {
            variables: {
                uuid: uuid
            }
        })
    }, []);

    if (queryLoading) {
        console.log(queryLoading)
    }

    if (queryError != null) {
        console.log(queryError)
    }

    if (data === undefined) {
        return (<div>
            Loading
        </div>)
    }

    return (
        <div>
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="relative bg-white shadow-xl">
                    <h2 className="sr-only">Kontaktdaten</h2>

                    <div className="grid grid-cols-1 lg:grid-cols-1">
                        {/* Contact information */}
                        <div className="relative overflow-hidden py-10 px-6 bg-indigo-700 sm:px-10 xl:p-12">
                            <div className="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width={343}
                                    height={388}
                                    viewBox="0 0 343 388"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                                        fill="url(#linear1)"
                                        fillOpacity=".1"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="linear1"
                                            x1="254.553"
                                            y1="107.554"
                                            x2="961.66"
                                            y2="814.66"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#fff" />
                                            <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div
                                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                                aria-hidden="true"
                            >
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width={359}
                                    height={339}
                                    viewBox="0 0 359 339"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                                        fill="url(#linear2)"
                                        fillOpacity=".1"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="linear2"
                                            x1="192.553"
                                            y1="28.553"
                                            x2="899.66"
                                            y2="735.66"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#fff" />
                                            <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div
                                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                                aria-hidden="true"
                            >
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width={160}
                                    height={678}
                                    viewBox="0 0 160 678"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                                        fill="url(#linear3)"
                                        fillOpacity=".1"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="linear3"
                                            x1="192.553"
                                            y1="325.553"
                                            x2="899.66"
                                            y2="1032.66"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#fff" />
                                            <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div className="grid grid-cols-1 lg:grid-cols-2">
                                <div>
                                    <div className="flex items-center space-x-5">
                                        {data.businessCards[0].Logo && (
                                            <div className="flex-shrink-0">
                                                <div className="relative">
                                                    <img
                                                        className="h-16 w-16 rounded-full ring-2 ring-white bg-white"
                                                        src={(process.env.NODE_ENV === 'development' ? "http://localhost:1337" : "" ) + data.businessCards[0].Logo.url}
                                                        alt="image"
                                                    />
                                                    <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            <h3 className="text-lg font-medium text-white">
                                                { data.businessCards[0].FirstName !== undefined ? data.businessCards[0].FirstName : "" }
                                                {' '}
                                                { data.businessCards[0].LastName !== undefined ? data.businessCards[0].LastName : "" }
                                            </h3>
                                            {data.businessCards[0].Position && (
                                                <p className="text-base text-indigo-50 max-w-3xl">
                                                    {data.businessCards[0].Position}
                                                </p>
                                            )}
                                        </div>
                                    </div>


                                    <dl className="mt-8 space-y-6">
                                        { data.businessCards[0].BusinessName !== null && (
                                        <div>
                                            <dt>
                                                <span className="sr-only">Geschäftsname</span>
                                            </dt>
                                            <dd className="flex text-base text-indigo-50">
                                                <BriefcaseIcon className="flex-shrink-0 w-6 h-6 text-indigo-200" aria-hidden="true" />
                                                <span className="ml-3">
                                                    {data.businessCards[0].BusinessName}
                                                </span>
                                            </dd>
                                        </div>
                                        )}
                                        <dt>
                                            <span className="sr-only">Adresse</span>
                                        </dt>
                                        <dd className="flex text-base text-indigo-50">
                                            <HomeIcon className="flex-shrink-0 w-6 h-6 text-indigo-200" aria-hidden="true" />
                                            <span className="ml-3">
                                                { data.businessCards[0].AddressLine1 !== undefined ? data.businessCards[0].AddressLine1 : " " }
                                                {', '}
                                                { data.businessCards[0].Zip !== undefined ? data.businessCards[0].Zip : " " }
                                                {' '}
                                                { data.businessCards[0].City !== undefined ? data.businessCards[0].City : " " }
                                            </span>
                                        </dd>
                                        { data.businessCards[0].Phone !== null && (
                                            <div>
                                                <dt>
                                                    <span className="sr-only">Telefonnummer</span>
                                                </dt>
                                                <dd className="flex text-base text-indigo-50">
                                                    <PhoneIcon className="flex-shrink-0 w-6 h-6 text-indigo-200" aria-hidden="true" />
                                                    <span className="ml-3">
                                                        {data.businessCards[0].Phone}
                                                    </span>
                                                </dd>
                                            </div>
                                        )}
                                        { data.businessCards[0].CellPhone !== null && (
                                            <div>
                                                <dt>
                                                    <span className="sr-only">Mobilfunk</span>
                                                </dt>
                                                <dd className="flex text-base text-indigo-50">
                                                    <DeviceMobileIcon className="flex-shrink-0 w-6 h-6 text-indigo-200" aria-hidden="true" />
                                                    <span className="ml-3">
                                                        {data.businessCards[0].CellPhone}
                                                    </span>
                                                </dd>
                                            </div>
                                        )}
                                        { data.businessCards[0].Email !== null && (
                                            <div>
                                                <dt>
                                                    <span className="sr-only">E-Mail</span>
                                                </dt>
                                                <dd className="flex text-base text-indigo-50">
                                                    <MailIcon className="flex-shrink-0 w-6 h-6 text-indigo-200" aria-hidden="true" />
                                                    <span className="ml-3">
                                                        { data.businessCards[0].Email }
                                                    </span>
                                                </dd>
                                            </div>
                                        )}
                                    </dl>

                                    <ul className="mt-8 flex space-x-12" role="list">

                                        { data.businessCards[0].WebsiteURL !== null && (
                                            <li>
                                                <a className="text-indigo-200 hover:text-indigo-100" href={data.businessCards[0].WebsiteURL}>
                                                    <span className="sr-only">Xing</span>
                                                    <GlobeAltIcon className="flex-shrink-0 w-6 h-6 text-indigo-200 hover:text-indigo-100" aria-hidden="true" />
                                                </a>
                                            </li>
                                        )}

                                        { data.businessCards[0].XingURL !== null && (
                                            <li>
                                                <a className="text-indigo-200 hover:text-indigo-100" href={data.businessCards[0].XingURL}>
                                                    <span className="sr-only">Xing</span>
                                                    <svg
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-6 h-6"
                                                        aria-hidden="true">
                                                        <path
                                                            fill="currentColor"
                                                            d="M14.887 24l-5.324-9.667 8.07-14.333h4.933l-8.069 14.333 5.27 9.667h-4.88zm-7.291-19h-4.939l2.768 4.744-4.115 7.256h4.914l4.117-7.271-2.745-4.729z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </a>
                                            </li>
                                        )}
                                        { data.businessCards[0].LinkedInURL !== null && (
                                            <li>
                                                <a className="text-indigo-200 hover:text-indigo-100" href={data.businessCards[0].LinkedInURL}>
                                                    <span className="sr-only">LinkedIn</span>
                                                    <svg
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-6 h-6"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </a>
                                            </li>
                                        )}
                                        { data.businessCards[0].GitHubURL !== null && (
                                            <li>
                                                <a className="text-indigo-200 hover:text-indigo-100" href={ data.businessCards[0].GitHubURL}>
                                                    <span className="sr-only">GitHub</span>
                                                    <svg
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-6 h-6"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M11.999 0C5.373 0 0 5.373 0 12c0 5.302 3.438 9.8 8.207 11.386.6.11.819-.26.819-.578 0-.284-.01-1.04-.017-2.04-3.337.724-4.042-1.61-4.042-1.61-.545-1.386-1.332-1.755-1.332-1.755-1.09-.744.082-.73.082-.73 1.205.086 1.838 1.238 1.838 1.238 1.07 1.833 2.81 1.304 3.493.996.109-.775.419-1.303.762-1.603C7.145 17 4.343 15.97 4.343 11.373c0-1.31.468-2.382 1.236-3.22-.124-.304-.536-1.524.118-3.176 0 0 1.007-.323 3.3 1.23.956-.266 1.983-.4 3.003-.404 1.02.005 2.046.138 3.005.404 2.29-1.553 3.296-1.23 3.296-1.23.655 1.652.243 2.872.12 3.176.77.838 1.233 1.91 1.233 3.22 0 4.61-2.806 5.624-5.478 5.921.43.37.814 1.103.814 2.223 0 1.603-.015 2.898-.015 3.291 0 .321.217.695.825.578C20.565 21.796 24 17.3 24 12c0-6.627-5.373-12-12.001-12"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                </a>
                                            </li>
                                        )}
                                        { data.businessCards[0].TwitterURL !== null && (
                                            <li>
                                                <a className="text-indigo-200 hover:text-indigo-100" href={data.businessCards[0].TwitterURL}>
                                                    <span className="sr-only">Twitter</span>
                                                    <svg
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-6 h-6"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M7.548 22.501c9.056 0 14.01-7.503 14.01-14.01 0-.213 0-.425-.015-.636A10.02 10.02 0 0024 5.305a9.828 9.828 0 01-2.828.776 4.94 4.94 0 002.165-2.724 9.867 9.867 0 01-3.127 1.195 4.929 4.929 0 00-8.391 4.491A13.98 13.98 0 011.67 3.9a4.928 4.928 0 001.525 6.573A4.887 4.887 0 01.96 9.855v.063a4.926 4.926 0 003.95 4.827 4.917 4.917 0 01-2.223.084 4.93 4.93 0 004.6 3.42A9.88 9.88 0 010 20.289a13.941 13.941 0 007.548 2.209"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className="mt-16 md:mt-0">
                                    <div className="flex h-full justify-center items-center">
                                        <a href={process.env.GATSBY_STAPI_GRAPHQL + "/business-cards/vcard/" + data.businessCards[0].id}>
                                            <DownloadIcon className="hover:text-indigo-100 flex-shrink-0 w-24 h-24 text-indigo-200" aria-hidden="true" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
